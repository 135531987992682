
.confirm-modal{
  width: 50% !important;
  .ant-modal-content {
    background: rgba(255, 251, 246, 1);
    border-radius: 30px;
    .ant-modal-body {
      font-family: Montserrat, serif;
      font-size: 48px;
      text-align: center;
      margin-top:100px;
    }
    .close-icon {
      width: 32px;
      height: 32px;
    }
    .close-button {
      margin-top: 90px;
      margin-bottom: 80px;
    }
  }


}
.survey-container{
  margin-top: 20px;

  .survey-header {
    margin-top: 15px;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 48px;
  }
  .survey-title {
    margin-top: 20px;
    font-family: Montserrat, serif;
    font-size: 36px;
    line-height: 80px;
    text-align: center;
  }
  .survey-content {
    margin: 15px 22px;
    display: flex;
    .total-count {
      position: -webkit-sticky;
      position: sticky;
      border-radius: 30px;
      top: 0;
      height: 200px;
      text-align: center;
      padding-top: 110px;
      padding-bottom: 110px;
      width: 15%;
      background: rgba(255, 251, 246, 1);
      .current-progress {
        font-family: Montserrat, serif;
        font-size: 128px;
        line-height: 80px;
      }
      .total-progress {
        font-family: Montserrat, serif;
        font-size: 40px;
        line-height: 80px;
      }
    }
    .buttons {
      position: -webkit-sticky;
      position: sticky;
      border-radius: 30px;
      top: 0;
      height: 200px;
      margin-left: auto;
      text-align: center;
      padding-top: 110px;
      padding-bottom: 110px;
      width: 18%;
      background: rgba(255, 251, 246, 1);
      .button-text {
        font-family: Montserrat, serif;
        font-size: 45px;
        line-height: 80px;
        cursor: pointer;
        position: relative;
        &:nth-child(2) {
          margin-top: 20px;
        }
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 4.13px;
          background-image: url('../../assets/survey-button-border.svg');
          background-size: cover;
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.4s ease;
        }
        &:hover {
          padding-bottom: 0;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  .survey-footer {
    width: 100% ;
    height: 120px;
    text-align: center;
    background: white;
    .submit-button {
      width: 175px;
      height: 80px;
      margin-top: 20px;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 1000px) {
  .sub-instruction {
    font-size: 20px !important;
  }
  .question-input {
    margin-left: 40px !important;
  }
  .survey-content{
    .total-count {
      display: none;
    }
    .survey-question {
      margin-left: 0;
      margin-right: 0;
      .question-text {
        font-size: 20px;
      }
      .blank-fill-question {
        .question-input {
          font-size: 20px;
        }
        .textarea-input {
          width: 50%;
          font-size: 20px;
        }
      }
      .option {
        .checkbox-container{
          width: 25px;
          height: 25px;
          svg {
            width: 28px;
            height: 28px;
          }
          margin-right: 20px;
        }
        .option-text {
          font-size: 20px;
          bottom: 25px;
        }
      }
    }
    .buttons {
      display: none;
    }
  }
}
