.survey-question {
  width: 100%;
  margin-left: 50px;
  margin-right: 100px;
  .question {
    margin-bottom: 50px;
  }
  padding-bottom: 0;
  .sub-instruction {
    white-space: pre-line;
    margin-bottom: 50px;
    font-family: Montserrat, serif;
    font-size: 36px;
    line-height: 80px;
    background: rgba(255, 251, 246, 1);
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 50px;
    border-radius: 30px;
  }
}