.blank-fill-question {
  background: rgba(255, 251, 246, 1);
  padding-top: 80px;
  padding-bottom: 100px;
  border-radius: 30px;
  .question-text {
    font-family: Montserrat, serif;
    font-size: 36px;
    line-height: 80px;
    margin-left: 20px;
    margin-top: 5%;
    white-space: pre-line;
  }
  .input-wrapper {
    text-align: center;
  }
  .textarea-input {
    resize: none;
    font-family: Montserrat, serif;
    font-size: 32px;
    width: 80%;
    padding: 50px 43px;
    border-radius: 30px;
    border: 3px solid rgba(0, 0, 0, 1);
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &::placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &:disabled {
      border-color: #B0B0B0;
      &::placeholder {
        color: #B0B0B0 ;
      }
    }
  }

}