.selection-question {
  background: rgba(255, 251, 246, 1);
  padding-top: 80px;
  padding-bottom: 30px;
  border-radius: 30px;
  .question-text {
    font-family: Montserrat, serif;
    font-size: 36px;
    line-height: 80px;
    margin-left: 20px;
    margin-top: 5%;
    margin-bottom: 60px;
    white-space: pre-line;
  }
  .question-input {
    margin-left: 200px;
    width: 50%;
    border: none;
    border-bottom: 5px solid rgba(0, 0, 0, 1);
    background: rgba(255, 251, 246, 1);
    font-family: Montserrat, serif;
    font-size: 32px;
    &:focus {
      outline: none;
    }
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &::placeholder {
      color: rgba(0, 0, 0, 1);
    }
  }

  .option{
    margin-bottom: 70px;
    padding-left: 52px;
    display: flex;
    &-other {
      margin-bottom: 0;
    }
    &-text {
      position: relative;
      bottom: 10px;
      font-family: Montserrat, serif;
      font-size: 36px;
      line-height: 80px;
    }
    .checkbox-container {
      width: 50px;
      height: 50px;
      margin-right: 100px;
      cursor: pointer;

      .checkbox {
        width: 100%;
        height: 100%;
        transition: transform 300ms ease-in-out;
        transform-origin: center center;
      }

      .checkbox.animating {
        transform: scale(0.5);
      }

      .checkbox.normal {
        transform: scale(1);
      }
    }
  }
}