.admin-survey-text{
  margin-top: 20px;
  font-family: Montserrat, serif;
  font-size: 48px;
  text-align: center;
  font-weight: bold;
}
.overall-report-button {
  width: fit-content;
  text-align: right;
  margin: 20px 50px 20px auto;
  cursor: pointer;
}
.buttons {
  button:first-of-type {
    margin-right: 30px;
  }
}