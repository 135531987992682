
.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 0;
  position: relative;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}


.step-label {
  position: absolute;
  top: 30px;
  font-family: Montserrat, serif;
  font-size: 23px;
  text-align: center;
}
.long-text {
  left: -30px;
}

.short-text {
  left: -10px;
}

.step-label.active {
  color: #3f51b5;
}

.step-connector {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 3px;
  background-color: #C6C6C6;
  z-index: 0;
  transform: translateX(-50%);
}

.step-connector.active {
  background-color: #0043CE;
}

.step:last-child .step-connector {
  display: none;
}