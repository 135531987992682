.out-container {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 36px;
  padding-bottom: 40px;
  background: #C1121F;
  border-radius: 30px;
}
.list-container {
  background: #ffffff;
  border-radius: 30px;
  height: 600px;
  padding-bottom: 20px;
  overflow: auto;
  white-space: nowrap;
}

.survey-list__table {
  width: 100%;
  .ant-table-thead .ant-table-cell {
    font-family: Montserrat, serif;
    font-size: 32px;
    text-align: center;
    background: #ffffff;
    border-bottom: none;
    &:before {
      content: none !important;
    }
  }
  .index-text {
    font-family: Montserrat, serif;
    font-size: 48px;
    font-weight: 600;
    text-align: center;
  }
  .name-text {
    font-family: Montserrat, serif;
    font-size: 32px;
    font-weight: 600;
    text-align: left;
  }
  .progress-bar {
    display: inline;
  }
  .progress-text {
    margin-left: 24px;
    display: inline;
    font-family: Montserrat, serif;
    font-size: 40px;
  }
  &-row {
    cursor:pointer;
  }
}

@media (max-width: 1000px) {
  .out-container {
    margin-left: 14px;
    margin-right: 10px;
  }
  .survey-list__table {
    .ant-table-thead .ant-table-cell {
      font-size: 3vh;
    }
  .ant-table-cell{
    padding-right: 0 !important;
    width: fit-content;
  }
    .ant-progress {
      .ant-progress-outer {
        width: 136px !important;
      }
      .ant-progress-bg {
        height: 9px !important;
      }
    }

    .index-text {
      font-size: 2vh;
    }
    .name-text {
      font-size: 2vh;
    }
    .progress-text {
      margin-left: 20px;
      font-size: 2vh;
    }
  }
}
