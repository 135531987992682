.not-found {
  text-align: center;
  margin-top: 10%;
  .header-text {
    font-family: Montserrat, serif;
    font-size: 48px;
    line-height: 80px;
    font-weight: 700;
  }
  .sub-text {
    font-family: Montserrat, serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 80px;

  }
  &-logo {
    position: absolute;
    top: 20%;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1000px) {
  .not-found-logo {
    top: 35%;
  }
}