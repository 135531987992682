.admin-list-container {
  width: 100%;

  .title {
    margin-top: 10px;
    font-family: Montserrat, serif;
    font-size: 48px;
    text-align: center;
  }
  .editor-container {
    width: 90%;
    max-width: unset;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #FFFBF6;
    border-radius: 30px;
    .toolbar {
      overflow-x: auto;
      background: #FFFBF6;
    }
    .editor-input {
      background: #FFFBF6;
    }
  }
  .button-wrapper {
    text-align: center;
    .save-button {
      display: inline-block;
      cursor: pointer;
      text-align: center;
      width: fit-content;
      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }

}
.chart-container {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 30px;
  .chart-item {
    width: 30%;

  }
  .chart-table {
    margin: auto 30px auto;
    width: 30%;
    font-family: Montserrat, serif;
    .sub-text {
      margin: 20px 0;
      font-size: 20px;
      font-style: italic;
    }
    .table {
      text-align: center;
      font-size: 25px;
      width: 100%;
      border-collapse: collapse;
      th, td {
        border: 1px solid #1E90FF;
        padding: 20px;
      }

      th {
        background-color: #f4f4f4;
      }
    }
  }
}