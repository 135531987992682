.all-report {
  background: rgba(255, 251, 246, 1);
  padding-top: 30px;
  .editor-container {
    margin: 30px auto;
    width: 90%;
    .editor-inner {
      background: none;
    }
    .editor-paragraph {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .title {
    margin-top: 50px;
    font-family: Montserrat, serif;
    font-size: 48px;
    text-align: center;
  }
  .chart-container {
    .chart-item {
      width: 30%;
    }
    .chart-table{
      width: 50%;
      margin: auto 0;
    }
  }
  .generate-pdf-button {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
  }
}

.pdf-content {
  .page {
    page-break-after: always;
    text-align: center;
  }
  .image-page {
    position: relative;
  }
  .student-name-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Montserrat, serif;
    font-size: 48px;
    color: #fbe4ca;
    text-shadow: 2px 2px 0px #f79666;
    font-weight: bold;
    -webkit-print-color-adjust:exact;
    -webkit-filter:opacity(1);
    letter-spacing: 1px;
  }
  .report-image {
    margin-top: 30px;
    width: 552px;
    height: 981px;
  }
  .sub-text {
    text-align: left;
  }
}