.blank-fill-question {
  background: rgba(255, 251, 246, 1);
  padding-top: 80px;
  padding-bottom: 100px;
  border-radius: 30px;
  .question-text {
    font-family: Montserrat, serif;
    font-size: 36px;
    line-height: 80px;
    margin-left: 20px;
    margin-top: 5%;
    white-space: pre-line;
  }
  .question-input {
    margin-left: 50px;
    margin-top: 50px;
    width: 61%;
    border: none;
    border-bottom: 5px solid rgba(0, 0, 0, 1);
    background: rgba(255, 251, 246, 1);
    font-family: Montserrat, serif;
    font-size: 32px;
    &:focus {
      outline: none;
    }
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &::placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &:disabled {
      &::placeholder {
        color: #B0B0B0 ;
      }
      border-bottom-color: #B0B0B0;
    }
  }
}