.contact-page{
  margin-top: 100px;
  background: #ffffff;
  min-height: 650px;
  display: flex;
  overflow: hidden;
  width: 100%;
  .context {
    margin-left: auto;
    margin-right: auto;
  }
  .texts {
    font-family: ZenKakuGothic;
    font-weight: 800;
    font-size: 71.4px;
  }
  .qr-code {
    width: 256px;
    height: 256px;
    margin-top: 60px;
  }
}
@media (max-width: 1000px) {
  .contact-page {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    .image-contact {
      position: relative;
      width: 100%;
      height: auto;
    }
    .qr-code {
      margin-bottom: 45px;
    }
  }
}
