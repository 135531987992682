body,html {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'ZenKakuGothic';
  src: url('./fonts/ZenKakuGothicAntique-Bold.ttf') format('truetype');
  font-weight: 800;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.element-container {
  background: linear-gradient(to bottom, #1568B3, white 30% 60%, #C1121F);
  min-height: 100vh;
  margin: 0;
  &-survey {
    background: none;
  }

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin-dot-item {
  color: #1e90ff;
}

.ant-message-notice {
  margin-left: auto;
  margin-right: auto;
  .ant-message-notice-content {
    font-family: Montserrat, serif;
    font-size: 32px;
    text-align: center;
    padding: 10px 20px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}