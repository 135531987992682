.table-container {
  margin-bottom: 30px;
}
.admin-list-container {
  height: 80vh;
}
.sg-create-modal {
  .ant-modal-body {
    margin-top: 20px !important;
    padding-bottom: 50px;
  }
  .question-input {
    margin-left: 50px;
    margin-top: 50px;
    width: 61%;
    border: none;
    border-bottom: 5px solid rgba(0, 0, 0, 1);
    background: rgba(255, 251, 246, 1);
    font-family: Montserrat, serif;
    font-size: 32px;
    &:focus {
      outline: none;
    }
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &::placeholder {
      color: rgba(0, 0, 0, 1);
    }
    &:disabled {
      &::placeholder {
        color: #B0B0B0 ;
      }
      border-bottom-color: #B0B0B0;
    }
  }
  .checkbox-container {
    text-align: left;
    .checkbox-group {
      .ant-row {
        font-family: Montserrat, serif;
        font-size: 26px;
        margin-bottom: 10px;
      }
      margin-top: 0;
      width: 100%;
      flex-direction: column;
    }
  }
}

.admin-table {
  .link-button {
    position: relative;
    bottom: 10px;
    margin-left: 60px;
  }
  .ant-table-row:nth-child(3n) {
    cursor: unset;
    background: #C1121F;
    .ant-table-cell-row-hover {
      background: #C1121F;
    }
  }
  .ant-table-cell:first-child {
    text-align: center !important;
  }
  .ant-table-cell {
    text-align: left !important;
  }

  .ant-table-row:last-child{
    background: none;
  }
}