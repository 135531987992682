.footer {
  background: #ffffff;
  width: 304px;
  height: 96px;
  margin: 34px auto 0px auto;
  border-radius: 21px;
  .icon-list {
    width: 65px;
    height: 65px;
    margin: 15px 98px 0 42px;
    &:hover {
      cursor: pointer;
      path{
        stroke: #C1121F;
      }
    }
  }
  .icon-contact {
    width: 65px;
    height: 65px;
    &:hover {
      cursor: pointer;
      path{
        stroke: #C1121F;
      }
    }
  }
}
