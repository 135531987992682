.header {
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #89b4d6;
  svg {
    width: 75vw;
    margin-top: 5px;
    height: 70px;
    margin-bottom: 25px;
  }
  &-white {
    background: white;
  }
}