.login {
  .login-background {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 120px auto;
    z-index: 0;
  }
  .login-content {
    position: relative;
    margin: 150px auto;
    width: 500px;
    text-align: left;
    z-index: 200;

    .input-field {
      margin-bottom: 30px;
      height: 55px;
      .ant-input,
      .ant-input-password {
        font-size: 32px;
        line-height: 48px;
        border-radius: 7px;
        &::-ms-input-placeholder {
          color: rgba(0, 0, 0, 1);
        }
        &::placeholder {
          color: rgba(0, 0, 0, 1);
        }
      }
      &-first {
        margin-top: 55px;
      }
    }

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
      .checkbox {
        font-size: 20px;
        .ant-checkbox {
          position: relative;
          top: 2px;
        }
        span:nth-child(2) {
          padding-left: 14px;
        }
      }
      .forgot-password {
        font-size: 20px;
        color: #4d4d4d;
        text-decoration: unset;
      }
    }

    .login-button {
      margin-top: 30px;
      width: 80%;
      height: fit-content;
      background-color: #000;
      border-radius: 35px;
      color: #fff;
      font-size: 28px;
      font-weight: 500;
      padding: 20px 0;

      &:hover {
        background-color: #333;
      }
    }
  }
  .login-text {
    font-size: 40px;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: -0.015em;
  }
}
@media (max-height: 900px) {
  .login {
    .login-background {
      display: none;
    }
  }
}


@media (max-width: 1000px), (max-height: 800px) {
  .login {
    .login-background {
      width: 100%;
    }
    .login-content {
      width: 70%;
      .input-field {
        margin-bottom: 30px;
        height: 40px;
        .ant-input,
        .ant-input-password {
          font-size: 20px;
          line-height: 30px;
        }
        &-first {
          margin-top: 35px
        }
      }
      .login-text {
        font-size: 33px;
        line-height: 42px;
      }
      .options {
        .checkbox {
          font-size: 10px;
        }
        .forgot-password {
          font-size: 10px;
          color: #4d4d4d;
          text-decoration: unset;
        }
      }
      .login-button {
        height: fit-content;
        background-color: #000;
        border-radius: 35px;
        color: #fff;
        font-size: 14px;
        padding: 10px 0;
      }
    }


  }

}